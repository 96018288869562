const enInitialText = `In The Court of Hon'ble -- Judge -- at --,
--
Versus
--
---- Appeal Number --/20--
`;
const mrInitialText = `-- येथील मेहरबान -- न्यायाधीश यांचे कोर्टात,
--
विरुद्ध 
--
दावा क्र --/२०-- `;

export const initialText = {
    'en': enInitialText,
    'mr': mrInitialText,
    'gu': '',
    'hi': '',
}


export const punctuation = {
    en: [
      {text: 'comma', symbol: ','},
      {text: 'full stop', symbol: '.'},
      {text: 'semi colon', symbol: ';'},
      {text: 'colon', symbol: ':'},
      {text: 'next paragraph', symbol: '\n'},
      {text: 'space', symbol: '  '},
      {text: 'question mark', symbol: '?'},
      {text: 'dash', symbol: '-'},
      {text: 'oblique', symbol: '/'}

    ],
    mr: [
      {text: 'स्वल्पविराम', symbol: ','},
      {text: 'पूर्णविराम', symbol: '.'},
      {text: 'अर्धविराम', symbol: ';'},
      {text: 'अपूर्णविराम', symbol: ':'},
      {text: 'पुढील ओळ', symbol: '\n'},
      {text: 'प्रश्न चिन्ह', symbol: '?'},
      {text: 'संयोग चिन्ह', symbol: '-'},
      {text: 'अपसरण चिन्ह', symbol: '―'},
      {text: 'ऑब्लिक', symbol: '/'}, 

    ],
    hi: [
      {text: 'अल्पविराम', symbol: ','},
      {text: 'पूर्णविराम', symbol: '|'},
      {text: 'अर्धविराम', symbol: ';'},
      {text: 'उपविराम', symbol: ':'},
      {text: 'अगली पंक्ति', symbol: '\n'},
      {text: 'प्रश्नवाचक चिन्ह', symbol: '?'},
      {text: 'योजक चिन्ह', symbol: '-'},
      {text: 'निर्देशक चिन्ह', symbol: '―'},
    ],
    gu: [
      {text: 'અલ્પવિરામ', symbol: ','},
      {text: 'પૂર્ણવિરામ', symbol: '.'},
      {text: 'અર્ધવિરામ', symbol: ';'},
      {text: 'ગુરુવિરામ', symbol: ':'},
      {text: 'આગલી પંક્તિ', symbol: '\n'},
      {text: 'પ્રશ્નાર્થ ચિહ્ન', symbol: '?'},
      {text: 'ગુરુરેખા ચિહ્ન', symbol: ' - '},
      {text: 'લઘુરેખા ચિહ્ન', symbol: '―'}
    ]
  };

export const transformText = (language, final_transcript) => {

    if(language === 'gu'){
      final_transcript = final_transcript.replace(/ અલ્પવિરામ/gi, ",");
      final_transcript = final_transcript.replace(/ પૂર્ણવિરામ/gi, ".");
      final_transcript = final_transcript.replace(/ અર્ધવિરામ/gi, ";");
      final_transcript = final_transcript.replace(/ ગુરુવિરામ/gi, ":");
      final_transcript = final_transcript.replace(/ પ્રશ્નાર્થ ચિહ્ન/gi, "?");
      final_transcript = final_transcript.replace(/ ગુરુરેખા ચિહ્ન/gi, " - ");
      final_transcript = final_transcript.replace(/ લઘુરેખા ચિહ્ન /gi, "―");
      final_transcript = final_transcript.replace(/ આગલી પંક્તિ/gi, '\n');
    }
    else if(language === 'hi'){
      final_transcript = final_transcript.replace(/ अर्धविराम/gi, ";");
      final_transcript = final_transcript.replace(/ अर्ध विराम/gi, ";");
      final_transcript = final_transcript.replace(/ पूर्णविराम/gi, "।");
      final_transcript = final_transcript.replace(/ पूर्ण विराम/gi, "।");
      final_transcript = final_transcript.replace(/ प्रश्नवाचक चिन्ह/gi, "?"); 
      final_transcript = final_transcript.replace(/ अल्पविराम/gi, ",");
      final_transcript = final_transcript.replace(/ अल्प विराम/gi, ",");
      final_transcript = final_transcript.replace(/ उपविराम/gi, ":");
      final_transcript = final_transcript.replace(/ उप विराम/gi, ":");
      final_transcript = final_transcript.replace(/ योजक चिन्ह /gi, "-");
      final_transcript = final_transcript.replace(/ निर्देशक चिन्ह/gi, "―");
      final_transcript = final_transcript.replace(/ अगली पंक्ति/gi, "\n");
    }
    else if(language === 'mr'){
      final_transcript = final_transcript.replace(/ शून्य /gi, "०");
      final_transcript = final_transcript.replace(/ एक /gi, "१");
      final_transcript = final_transcript.replace(/ दोन /gi, "२");
      final_transcript = final_transcript.replace(/ तीन /gi, "३");
      final_transcript = final_transcript.replace(/ चार /gi, "४");
      final_transcript = final_transcript.replace(/ पाच /gi, "५");
      final_transcript = final_transcript.replace(/ सहा	/gi, "६");
      final_transcript = final_transcript.replace(/ सात /gi, "७");
      final_transcript = final_transcript.replace(/ आठ /gi, "८");
      final_transcript = final_transcript.replace(/ नऊ /gi, "९");

      final_transcript = final_transcript.replace(/ स्पेस/gi, " ");
      final_transcript = final_transcript.replace(/ डॉट/gi, ".");
      final_transcript = final_transcript.replace(/ फुल्ल स्टॉप/gi, ".");
      final_transcript = final_transcript.replace(/ फूल स्टॉप/gi, ".");
      final_transcript = final_transcript.replace(/ फुल स्टॉप/gi, ".");
      final_transcript = final_transcript.replace(/ कामा/gi, ",");
      final_transcript = final_transcript.replace(/ कोमा/gi, ",");
      final_transcript = final_transcript.replace(/ कॉमा/gi, ",");
      final_transcript = final_transcript.replace(/ नेक्स्ट पेराग्राफ/gi, "\n");
      final_transcript = final_transcript.replace(/ नेस्ट पॅराग्राफ/gi, "\n");
      final_transcript = final_transcript.replace(/ नेक्स्ट पॅरेग्राफ/gi, "\n");
      final_transcript = final_transcript.replace(/ नेस्ट पॅरेग्राफ/gi, "\n");
      final_transcript = final_transcript.replace(/ नेस्ट पॅरा/gi, "\n");
      final_transcript = final_transcript.replace(/ नेस्ट पेरा/gi, "\n");
      final_transcript = final_transcript.replace(/ नेक्स्ट पेरा/gi, "\n");
      final_transcript = final_transcript.replace(/ नेक्स्ट पॅरा/gi, "\n");
      final_transcript = final_transcript.replace(/ ऑब्लिक/gi, "/");
      final_transcript = final_transcript.replace(/ डॅश/gi, "-");

      final_transcript = final_transcript.replace(/ अर्धविराम/gi, ";");
      final_transcript = final_transcript.replace(/ अर्ध विराम/gi, ";");
      final_transcript = final_transcript.replace(/ पूर्णविराम/gi, ".");
      final_transcript = final_transcript.replace(/ पूर्ण विराम/gi, ".");
      final_transcript = final_transcript.replace(/ प्रश्न चिन्ह/gi, "?");
      final_transcript = final_transcript.replace(/ प्रश्नचिन्ह/gi, "?");
      final_transcript = final_transcript.replace(/ स्वल्पविराम/gi, ",");
      final_transcript = final_transcript.replace(/ स्वल्प विराम/gi, ",");
      final_transcript = final_transcript.replace(/ अपूर्णविराम/gi, ":");
      final_transcript = final_transcript.replace(/ अपूर्ण विराम/gi, ":");
      final_transcript = final_transcript.replace(/ संयोग चिन्ह/gi, "-");
      final_transcript = final_transcript.replace(/ संयोगचिन्ह/gi, "-");
      final_transcript = final_transcript.replace(/ अपसरण चिन्ह/gi, "―");
      final_transcript = final_transcript.replace(/ अपसरणचिन्ह/gi, "―");
      final_transcript = final_transcript.replace(/ पुढील ओळ/gi, "\n");
    }
    else if(language === 'en'){
      final_transcript = final_transcript.replace(/ comma/gi, ",");
      final_transcript = final_transcript.replace(/ coma/gi, ",");
		  final_transcript = final_transcript.replace(/ full stop/gi, ".");
      final_transcript = final_transcript.replace(/ fullstop/gi, ".");
      final_transcript = final_transcript.replace(/ colon/gi, ":");
      final_transcript = final_transcript.replace(/ semicolon/gi, ";");
      final_transcript = final_transcript.replace(/ semi colon/gi, ";");
      final_transcript = final_transcript.replace(/ question mark/gi, "?");
      final_transcript = final_transcript.replace(/ dash/gi, " - ");
      final_transcript = final_transcript.replace(/ next paragraph/gi, "\n");
      final_transcript = final_transcript.replace(/ next para/gi, "\n");
      final_transcript = final_transcript.replace(/ space/gi, " ");
      final_transcript = final_transcript.replace(/ oblique/gi, "/");
    }

    return final_transcript;
  }