import { useDispatch } from "react-redux";
import React, { useState, useRef, useEffect } from 'react';
import { isUserActive } from "../../store/index"
import { unicode_to_krutidev, krutidev_to_unicode } from "../../utils/krutidev";
import { unicode_to_shivaji, shivaji_to_unicode } from "../../utils/shivaji";
import { initialText, transformText, punctuation } from "./utils";

const Dictaphone = () => {
  const dispatch = useDispatch();
  const textareaElement = useRef(null);

  useEffect(() => {
    const area = textareaElement.current;
    area.scrollTop = area.scrollHeight;
  })

  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const speechRecognizerObj = new SpeechRecognition();

  const [speechRecognizer, setSpeechRecognizer] = useState(speechRecognizerObj);


  const [language, setLanguage] = useState('en')
  const [font, setFont] = useState('georgia')
  const [listeningState, setListeningState] = useState('Start Listening')
  const [output, setOutput] = useState("")
  const [speechToTextClass, setSpeechToTextClass] = useState('btn btn-success')


  const clearOutput = () => {
    setOutput('')
  }

  const download = () => {
    const element = document.createElement("a");
    const file = new Blob([output], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = "download.doc";
    document.body.appendChild(element);
    element.click();
  }

  const print = () => {
    let childWindow = window.open('', 'childWindow', 'location=yes, menubar=yes, toolbar=yes');
    childWindow.document.open();
    childWindow.document.write('<html><head></head><body>');
    childWindow.document.write(output.replace(/\n/gi, '\\n'));
    childWindow.document.write('</body></html>');
    childWindow.print();
    childWindow.document.close();
    childWindow.close();
  }

  const copy = () => {
    navigator.clipboard.writeText(output)
  }

  const addInitialText = () => {
    setOutput(prevState =>  prevState + "\n" + initialText[language]);
  }

  const placeholder = ` To start translating your speech, press Start Listening button and speak!`

  const selectHandler = (event) => {
    setLanguage(event.target.value)
  }

  const speechToText = () => {
    const token = localStorage?.getItem("getUserAuthToken") ?? ''
    dispatch(isUserActive({
      token: token
    }))

    if (listeningState === 'Stop Listening') {
      stopConverting()
      setListeningState('Start Listening')
      setSpeechToTextClass('btn btn-success')
    }
    else {
      startConverting()
      setListeningState('Stop Listening')
      setSpeechToTextClass('btn btn-danger')
    }
  }

  const startConverting = () => {

    if ('webkitSpeechRecognition' in window) {


      speechRecognizer.continuous = true
      speechRecognizer.interimResults = true
      speechRecognizer.lang = language
      speechRecognizer.start();

      let finalTranscripts = output;

      speechRecognizer.onresult = (event) => {

        let interimTranscripts = '';
        for (let i = event.resultIndex; i < event.results.length; i++) {
          let transcript = event.results[i][0].transcript;

          if (event.results[i].isFinal) {
            finalTranscripts += transformText(language, transcript);
          }
          else {
            interimTranscripts += transformText(language, transcript);
          }
        }

        const updateOp = finalTranscripts + interimTranscripts



        setOutput(updateOp);
      };
    }
  }

  const stopConverting = () => {
    speechRecognizer.stop();
  }

  const updateOutput = (event) => {
    setOutput(event.target.value)

    if (output != event.target.value && listeningState === 'Stop Listening') {
      stopConverting()
      setListeningState('Start Listening')
      setSpeechToTextClass('btn btn-success')
    }
  }

  const selectFontHandler = (event) => {
    try {

      const convert_Font = (font, value) => {
        let text = value;
        if (font === 'krutiDev' || font === 'krishna') {
          text = unicode_to_krutidev(value);
        }
        else if (font === 'shivaji') {
          text = unicode_to_shivaji(value);
        }
        setFont(font);
        setOutput(text);
      }

      if (font === "shivaji") {
        const text = shivaji_to_unicode(output);
        convert_Font(event.target.value, text);
      }
      else if (font === "krutiDev" || font === "krishna") {
        const text = krutidev_to_unicode(output)
        console.log(text)
        convert_Font(event.target.value, text);
      }
      else {
        convert_Font(event.target.value, output);
      }

    }
    catch (error) {
      console.log(error);
    }
  }


  return (

    <div className="container-fluid" >

      <div className="subheader-text" id="speechtotext">
        Voice Dictation
      </div>

      <div className="row">

        <div className="col-sm-9">

          <div className="row m-2 p-2">
            <span className="inline-div">
              <span className="inline-div m-1">
                <label>Choose Language: </label>
              </span>
              <span className="inline-div m-1">
                <select className="form-control" onChange={selectHandler}>
                  <option value="en" selected={language === "en"}>English</option>
                  <option value="gu" selected={language === "gu"}>Gujarati</option>
                  <option value="hi" selected={language === "hi"}>Hindi</option>
                  <option value="mr" selected={language === "mr"}>Marathi</option>
                </select>
              </span>
            </span>

            <span className="inline-div">
              <button type="button" id="record" className={`m-1 ${speechToTextClass}`} onClick={speechToText}>
                {listeningState}
              </button>
            </span>

            {language != 'en' && <span className="inline-div">
              <span className="inline-div m-1">
                <select className="form-control" onChange={selectFontHandler}>
                  <option value="mangal" selected={font === "mangal"}>Mangal</option>
                  <option value="krutiDev" selected={font === "krutiDev"}>KrutiDev</option>
                  <option value="krishna" selected={font === "krishna"}>Krishna</option>
                  <option value="shivaji" selected={font === "shivaji"}>Shivaji</option>
                </select>
              </span>
            </span>
            }
          </div>

          <br />

          <textarea
            ref={textareaElement}
            className={`form-control text-justify p-3`}
            style={{ fontFamily: font, fontWeight: 'bold', fontSize: '16px' }}
            rows="15"
            placeholder={placeholder}
            value={output}
            onChange={updateOutput}
          ></textarea>

          <br></br>

          <div>
            <span className="inline-div">
              <button type="button" className="btn btn-secondary m-1" onClick={download}>
                Download
              </button>
            </span>

            <span className="inline-div">
              <button type="button" className="btn btn-secondary m-1" onClick={print}>
                Print
              </button>
            </span>

            <span className="inline-div">
              <button type="button" className="btn btn-secondary m-1" onClick={copy}>
                Copy
              </button>
            </span>

            <span className="inline-div">
              <button type="button" className="btn btn-secondary m-1" onClick={clearOutput}>
                Clear
              </button>
            </span>
            <span className="inline-div">
              <button type="button" className="btn btn-secondary m-1" onClick={addInitialText}>
                Add Initial Text
              </button>
            </span>
          </div>

          <div className="row m-2 p-2">

            <span className="inline-div">
              <span className="inline-div m-1">
                <label>Choose Language: </label>
              </span>
              <span className="inline-div m-1">
                <select className="form-control" onChange={selectHandler}>
                  <option value="en" selected={language === "en"}>English</option>
                  <option value="gu" selected={language === "gu"}>Gujarati</option>
                  <option value="hi" selected={language === "hi"}>Hindi</option>
                  <option value="mr" selected={language === "mr"}>Marathi</option>
                </select>
              </span>
            </span>

            <span className="inline-div">
              <button type="button" id="record" className={`m-1 ${speechToTextClass}`} onClick={speechToText}>
                {listeningState}
              </button>
            </span>

            {language != 'en' && <span className="inline-div">
              <span className="inline-div m-1">
                <select className="form-control" onChange={selectFontHandler}>
                  <option value="mangal" selected={font === "mangal"}>Mangal</option>
                  <option value="krutiDev" selected={font === "krutiDev"}>KrutiDev</option>
                  <option value="krishna" selected={font === "krishna"}>Krishna</option>
                  <option value="shivaji" selected={font === "shivaji"}>Shivaji</option>
                </select>
              </span>
            </span>
            }

          </div>
        </div>

        <div className="col-sm-3">
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th>Symbol</th>
                <th>Voice Command</th>
              </tr>
              {
                punctuation[language].map((punctuationObj, index) => {
                  if (index < 10) {
                    return (
                      <tr key={index}>
                        <td><strong>{punctuationObj.symbol}</strong></td>
                        <td >{punctuationObj.text}</td>
                      </tr>
                    );
                  }
                })
              }
            </tbody>
          </table>
        </div>

      </div>

    </div>
  );
};

export default Dictaphone;
