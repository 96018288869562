import banner from '../images/banner.png'

const Banner = () =>{
    return (
        <div className="container">
            
            <div className="center-div slogan">
                <h3>
                    Now type with your Voice in <span className="animate-text"></span>
                </h3>
            </div>

            <div >
                <img className="col-sm-8 center-div" src={banner}/>
            </div>
        </div>
    );
}

export default Banner;